import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Spin, Table } from 'antd';
import cn from 'classnames';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import {
  getUseShopQuoteStoreByCode,
  LK_SELECTED_COUPON_CODE,
  ShopOrderQuoteStoreModifiers,
  useShopExpressStore,
} from '../../../../../../stores';
import { DeliveryTimeTitle } from '../../../../components/info/DeliveryTimeTitle';
import styles from './index.module.less';

export interface ShopQuoteShippingMethodMgtProps {
  className?: string;
  style?: Record<string, string | number>;

  orderCode: string;
}

export const ShopQuoteShippingMethodMgt = ({
  className,
  style,

  orderCode,
}: ShopQuoteShippingMethodMgtProps) => {
  const shopOrderStore = getUseShopQuoteStoreByCode(orderCode);
  const { isLoading, allowEdit, shopOrder, orderPrice, quotation } =
    shopOrderStore(s => ({
      isLoading: s.isLoading,
      shopOrder: s.shopOrder,
      allowEdit: s.allowEdit,
      orderPrice: s.shopOrderPrice,
      quotation: s.shopOrderQuotation,
    }));

  const [preAddress, setPreAddress] = useState<
    S.ShopOrderDeliveryItem | S.ShopOrderDeliveryItemRequestParams
  >();

  const deliveryAddress = S.get(shopOrder, () => shopOrder.delivery.items[0]);

  const { expressList, loadExpress } = useShopExpressStore(s => ({
    expressList: s.shopExpresses,
    loadExpress: s.loadShopExpresses,
  }));

  const dataSoure = useMemo(() => {
    if (!S.isValidArray(expressList)) return [];

    const expressIds = S.get(quotation, q => q.delivery.prices, []).map(
      e => e.expressId,
    );

    /** 待客户确认状态只返回已选物流信息 */
    if (shopOrder && shopOrder.status == 'WAIT_CUSTOM_CONFIRM') {
      const currentId = S.get(
        shopOrder,
        o => o.requirements.customerSelections.delivery.expressId,
      );

      return expressList.filter(e => e.id == currentId);
    }

    console.log('expressList', expressList);

    // return expressList.filter(e => expressIds.includes(e.id));
    return expressList;
  }, [expressList, quotation, shopOrder]);

  useEffect(() => {
    const expressId = S.get(
      shopOrder,
      o => o.requirements.customerSelections.delivery.expressId,
    );

    /** 有可选物流但订单没选择物流时默认选中第一个物流 */
    if (S.isValidArray(dataSoure) && !expressId && !isLoading) {
      ShopOrderQuoteStoreModifiers.updateOrder({
        orderCode,
        onChange: o => {
          o.requirements.customerSelections.delivery.expressId =
            dataSoure[0].id;
        },
      });
    }

    /** 当前所选物流不在 dataSoure 内时更新订单 */
    if (
      expressId &&
      !isLoading &&
      S.isValidArray(dataSoure) &&
      !(dataSoure || []).find(a => a.id == expressId)
    ) {
      ShopOrderQuoteStoreModifiers.updateOrder({
        orderCode,
        onChange: o => {
          o.requirements.customerSelections.delivery.expressId = null;
        },
      });
    }
  }, [dataSoure, shopOrder, isLoading]);

  useEffect(() => {
    if (
      !!deliveryAddress &&
      (!preAddress || !isEqual(deliveryAddress, preAddress))
    ) {
      setPreAddress(deliveryAddress);

      deliveryAddress.country && loadExpress(deliveryAddress.country);
    }
  }, [deliveryAddress, preAddress]);

  return (
    <div
      id="ShopQuoteShippingMethodMgt"
      className={cn(className, styles.container)}
      style={style}
    >
      <Table<S.ShopExpress>
        rowKey="id"
        pagination={false}
        columns={[
          {
            title: i18nFormat('Shipping Method'),
            key: 'name',
            dataIndex: 'name',
          },
          {
            title: i18nFormat('Costs'),
            key: 'costs',
            render: (_, e) => {
              const delivery = S.get(quotation, q => q.delivery);

              if (!delivery) return '-';

              const targetQuotation = (delivery.prices || []).find(
                p => p.expressId === e.id,
              );

              if (S.get(targetQuotation, t => t.status) == 'COMPUTING') {
                return <Spin size="small" />;
              }

              const price = S.get(targetQuotation, t => t.price);

              if (
                S.get(targetQuotation, t => t.status) == 'RFQ' ||
                typeof S.get(price, p => p.amount) !== 'number'
              ) {
                return 'RFQ';
              }

              return S.toPriceStringWithCurrency(price);
            },
          },
          {
            title: <DeliveryTimeTitle />,
            key: 'deliveryStr',
            render: (_, e) => {
              return e.deliveryStr;
            },
          },
          {
            title: i18nFormat('Restriction'),
            key: 'restrictionDescription',
            render: (_, e) => {
              return e.restrictionDescription;
            },
          },
        ]}
        dataSource={dataSoure}
        rowSelection={{
          type: 'radio',
          selectedRowKeys: [
            S.get(
              shopOrder,
              s => s.requirements.customerSelections.delivery.expressId,
            ),
          ],
          getCheckboxProps: r => ({
            disabled:
              isLoading ||
              (!allowEdit &&
                orderPrice &&
                orderPrice.status !== 'OK' &&
                shopOrder.status == 'WAIT_REVIEW'),
          }),
          onSelect: r => {
            /** 切换物流公司前先清空优惠券信息 */
            shopOrderStore.setState({ couponCode: null });
            localStorage.removeItem(`${LK_SELECTED_COUPON_CODE}_${orderCode}`);

            ShopOrderQuoteStoreModifiers.updateOrder({
              orderCode,
              onChange: o => {
                o.requirements.customerSelections.delivery.expressId = r.id;
              },
            });
          },
        }}
      />
    </div>
  );
};

ShopQuoteShippingMethodMgt.displayName = 'ShopQuoteShippingMethodMgt';
